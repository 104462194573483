<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <router-link :to="`/profile/child/${child.id}`" class="back-button" title="back">
            <ion-icon slot="icon-only" color="tetranary" :icon="chevronBackOutline"></ion-icon>
          </router-link>
        </ion-buttons>

        <ion-title color="primary">
          <h2 class="mb-0 ion-text-center">Sleep Diary</h2>
        </ion-title>

        <ion-buttons slot="end">
          <div style="visibility: hidden"><ion-back-button default-href="/"></ion-back-button></div>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :scroll-events="true" class="ion-padding bg-white text-black">
      <div class="horizontally-centered">
        <h3>Two week sleep diary for {{ child.initials }}</h3>
        <div>SELECT DAY:</div>

        <div v-for="row in 2" :key="row" class="ion-padding-bottom ion-padding-top">
          Week {{ row }}
          <div class="flex justify-between">
            <div v-for="col in 7" :key="col">
              <ion-button
                class="rounded-button"
                :color="buttonColour(row, col)"
                expand="block"
                size="small"
                :router-link="`/profile/child/${childId}/calendar/${col + 7 * (row - 1)}`"
              >
                <span aria-hidden="true">{{ dayInitialFromColdRow(row, col) }}</span>
                <span class="sr-only">{{ dayNameFromColdRow(row, col) }}</span>
              </ion-button>
            </div>
          </div>
        </div>

        <div class="ion-margin-top">
          <ion-button color="tertiary" expand="block" size="large" @click.prevent="downloadCSV">
            Download calendar data
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import axios from '@/axios';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import {
  IonPage,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent
} from '@ionic/vue';

import { chevronBackOutline } from 'ionicons/icons';

export default {
  name: 'ChildCalendar',
  components: {
    IonPage,
    IonButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent
  },

  setup(): any {
    const store = useStore();
    const route = useRoute();

    const childId = Number(route.params.id) || null;
    const child = computed(() => store.getters['user/childById'](childId));

    const dayFromColdRow = (row, col) => {
      return col + 7 * (row - 1);
    };

    const dayNameFromColdRow = (row, col) => {
      const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      return days[col + -1];
    };

    const dayInitialFromColdRow = (row, col) => {
      return dayNameFromColdRow(row, col).substr(0, 1);
    };

    const buttonColour = computed(() => (row, col) => {
      if (!child.value.calendarEntries) {
        return 'white';
      }

      const day = dayFromColdRow(row, col);
      return child.value.calendarEntries[day] ? 'tertiary' : 'white';
    });

    const downloadCSV = async function() {
      const url = `wp-json/user/v1/child/${childId}`;
      axios({
        url,
        method: 'GET',
        responseType: 'blob' // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${child.value.initials}-calendar-entries.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    };

    return {
      chevronBackOutline,
      dayInitialFromColdRow,
      dayNameFromColdRow,
      dayFromColdRow,
      buttonColour,
      childId,
      child,
      downloadCSV
    };
  }
};
</script>
