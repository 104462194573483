
import axios from '@/axios';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import {
  IonPage,
  IonButton,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent
} from '@ionic/vue';

import { chevronBackOutline } from 'ionicons/icons';

export default {
  name: 'ChildCalendar',
  components: {
    IonPage,
    IonButton,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonContent
  },

  setup(): any {
    const store = useStore();
    const route = useRoute();

    const childId = Number(route.params.id) || null;
    const child = computed(() => store.getters['user/childById'](childId));

    const dayFromColdRow = (row, col) => {
      return col + 7 * (row - 1);
    };

    const dayNameFromColdRow = (row, col) => {
      const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      return days[col + -1];
    };

    const dayInitialFromColdRow = (row, col) => {
      return dayNameFromColdRow(row, col).substr(0, 1);
    };

    const buttonColour = computed(() => (row, col) => {
      if (!child.value.calendarEntries) {
        return 'white';
      }

      const day = dayFromColdRow(row, col);
      return child.value.calendarEntries[day] ? 'tertiary' : 'white';
    });

    const downloadCSV = async function() {
      const url = `wp-json/user/v1/child/${childId}`;
      axios({
        url,
        method: 'GET',
        responseType: 'blob' // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${child.value.initials}-calendar-entries.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    };

    return {
      chevronBackOutline,
      dayInitialFromColdRow,
      dayNameFromColdRow,
      dayFromColdRow,
      buttonColour,
      childId,
      child,
      downloadCSV
    };
  }
};
